import React from 'react';
import styled from 'styled-components';
import { graphql,Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';
import Blockquote from '../../../components/frills/Blockquote';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import ContactForm from '../../../components/forms/ContactForm';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: block;
    position: relative;
  }
`;

const HeroImageContainer = styled.div`
  height: 450px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 790px;
    width: 50%;
    margin-left: auto;
    transform: translate3d(0, -80px, 0);
  }
`;

const HeroContentContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% - 80px);
  }
`;

const Hero = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
    text-align: left;
    width: 40%;
  }
`;

const LogoContainer = styled.div`
  margin: 40px 0;

  > div {
    margin: 0 auto;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      margin: 0;
    }
  }
`;

const StatsBox = styled.div`
  padding: 0 20px;
  box-shadow: 0 0 8px 2px rgba(71, 91, 104, 0.1);
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transform: translateY(-160px);
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  height: 80px;
  padding: 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0;
  }
`;

const StatHeadline = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const StatSubtitle = styled(H4).attrs({
  uistyle: 'grey',
})``;

const BodyWrapper = styled.div`
  max-width: 640px;
  margin: -60px auto 0 auto;
`;

const TileTagContainer = styled.div`
  margin-bottom: 40px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const Section = styled.div`
  margin-bottom: 60px;
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const TextBlock = styled(Text).attrs({
  size: 'lg',
})``;

const PopQuote = styled(H4).attrs({
  uistyle: 'primary',
  fontWeight: 600,
})`
  text-align: center;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="Roseacre Customer Story - Rotaready"
          description="Here's how Roseacre Pub Company have been able to better deploy over 26 hours each week since using Rotaready, and achieve an ROI of 505%"
          url="customer-stories/hospitality-roseacre"
        />

        <HeroContainer>
          <HeroImageContainer>
            <GatsbyImage
              image={data.heroBackground.childImageSharp.gatsbyImageData}
              style={{ height: '100%' }}
              alt="Roseacre"
            />
          </HeroImageContainer>

          <HeroContentContainer>
            <ResponsiveContainer>
              <Hero>
                <TileTagContainer>
                  <TileTag text="Hospitality" />
                </TileTagContainer>

                <H3 uistyle="primary" text="Customer story" fontWeight={600} />

                <LogoContainer>
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    alt="Roseacre logo"
                  />
                </LogoContainer>

                <H3 uistyle="brand160" text="Here's how Roseacre Pub Company have been able to better deploy over 26 hours each week since using Rotaready, and achieve an ROI of 505%" />
              </Hero>
            </ResponsiveContainer>
          </HeroContentContainer>
        </HeroContainer>

        <ResponsiveContainer>
          <StatsBox>
            <StatItem>
              <StatHeadline text="8" />
              <StatSubtitle text="sites" />
            </StatItem>

            <StatItem>
              <StatHeadline text="138" />
              <StatSubtitle text="staff" />
            </StatItem>

            <StatItem>
              <StatHeadline text="505%" />
              <StatSubtitle text="ROI" />
            </StatItem>

            <StatItem>
              <StatHeadline text="27 hours" />
              <StatSubtitle text="better deployed each week" />
            </StatItem>
          </StatsBox>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <Section>
              <Content>
                <H3 uistyle="brand160" text="Roseacre" />
              </Content>

              <Content>
                <TextBlock text="Established in 2014, Roseacre Pub Company now own 8 pubs across Leicestershire. The pub group prides itself not just on the excellent food and drink it provides, but the service that comes with it." />
              </Content>

              <Content>
                <TextBlock text="When the group opened its first pubs, staff rotas were being scheduled using Excel spreadsheets. But as the business grew, this approach was no longer feasible - there was too much they couldn't do. The group trialled a staff scheduling solution for a couple of months but that too wasn't fit for the job. Its limited functionality didn't give Rosacre the control it wanted over its costs and was inflexible when it came to making any last minute changes to staff rotas." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="The perfect staff scheduling solution" />
              </Content>

              <Content>
                <TextBlock text="So in September 2017 the Roseacre team went back to the drawing board, and embarked on their search for the perfect staff scheduling partner." />
              </Content>

              <Content>
                <TextBlock>
                  For Roseacre, it was crucial that the chosen solution worked across multiple sites, gave them a real-time overview of forecasted vs live sales, and the means to accurately monitor staff <Link style="color:inherit;" to="/time-attendance">time and attendance</Link>. It was also key that it had advanced cost control functionality that would help optimise labour percentage and increase business efficiency as a result. In November 2017 the Roseacre team made the decision to go with Rotaready, which met all of their criteria.
                </TextBlock>
              </Content>

              <Content>
                <Blockquote
                  text="Set-up was really easy. Rotaready took care of all of our data imports and implementation and had all sites up and running in just two weeks. We've since opened three new sites and the transition has always been smooth. It's such a user friendly system which makes it really easy to train new managers. They always get it straight away."
                  attribution="Andy Griffin, Cluster Manager"
                />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Achieving the impossible" />
              </Content>

              <Content>
                <TextBlock>
                  Since implementing Rotaready, the team at Roseacre have been utilising most of the features including <Link style="color:inherit;" to="/rota-scheduling">rota scheduling</Link> and time & attendance. Managers are enjoying how easy it is to build, amend and share staff rotas; whilst employees love the visibility and control the Rotaready app gives them over their upcoming shifts and holiday. Rotaready also gives managers assurance that their team always knows exactly when they're working next.
                </TextBlock>
              </Content>

              <Content>
                <TextBlock text="One of the main benefits to the managers at Roseacre has been the increased visibility they gain from using Rotaready's cost control. Being able to access real- time wage spend, hours and sales data all in one place has helped the business avoid any unnecessary overspend." />
              </Content>

              <Content>
                <Blockquote
                  text="I use cost control constantly throughout the day to see how we're doing. The enhanced visibility and control it's given us over our costs has helped us achieve a labour percentage we previously thought to be impossible. It's gone from an average of 32% to under 26% and drops even further in the summer months; averaging around 22% in our wet led pubs."
                  attribution="Andy Griffin, Cluster Manager"
                />
              </Content>
            </Section>

            <Section>
              <PopQuote text="As a direct result of using Rotaready, Roseacre have been able to better deploy 26 hours and 54 minutes each week and achieve a return on investment of 505%." />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="What's next?" />
              </Content>

              <Content>
                <TextBlock text="Rotaready's customer success team is continuing to work closely with Roseacre to help them leverage even more benefits of the platform. Next on the agenda is Rotaready's demand forecasting feature. This will take Roseacre's staff scheduling to a whole new level, by enabling them to build staff rotas informed by past trends, upcoming events and seasonality." />
              </Content>

              <Content>
                <Blockquote
                  text="The support we've had from the Rotaready team has always been excellent. Whether it's via chat, phone or email - we always know it's definitely being dealt with. We're looking forward to working together in the future."
                  attribution="Andy Griffin, Cluster Manager"
                />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline1.childImageSharp.gatsbyImageData}
                alt="Roseacre team members"
              />
            </Section>

            <Section>
              <ContactForm
                title="Download the story"
                submitLabel="Download PDF"
                source="customerstories_roseacre"
                label="rotaready.com/customer-stories/hospitality-roseacre"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Happy reading 📚" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading this customer story! Click below to get the file." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/customer-stories/Rotaready-Story-Roseacre.pdf" download>
                        <Button uistyle="primary" text="Download PDF" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </Section>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackground: file(relativePath: { eq: "customer-stories/header-roseacre.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 50
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }

    logo: file(relativePath: { eq: "customer-stories/logo-roseacre.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 50, quality: 100)
      }
    }

    inline1: file(relativePath: { eq: "customer-stories/inline-roseacre.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }
  }
`;
